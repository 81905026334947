import auth from '@/actions/authorizations/index.js'

export default class {
  static attachParentDetailBadge(card, projectCache, cardRegistry, board) {
    return this.badge(
      {
        title: "Parent",
        text: "Attach...",
        callback: (t) => {
          return t.popup({
            title: 'Pick a Parent',
            items: this._attachOptions(card, projectCache, cardRegistry, board)
          });
        }
      }
    )
  }

  static _attachOptions(card, projectCache, cardRegistry, board) {
    return [
      {
        text: "Attach to Project...",
        callback: (t) => {
          this.attachToProject(card, projectCache, t, board)
        }
      },
      {
        text: "Attach to Scope...",
        callback: (t) => {
          this.attachToScope(card, projectCache, cardRegistry, t, board)
        }
      }
    ]
    // const array = Array.from($tpu.epics.colorOptions).map(([key, value]) => {
    //   return {
    //     text: value,
    //     callback: (t) => {
    //       $tpu.epics.update(card, { badgeColor: key }, t).then(() => {
    //         t.showCard(card.id)
    //       })
    //     }
    //   }
    // })

    // return array
  }

  static attachToProject(card, projectCache, t, board) {
    let options = []

    if (projectCache) { // Null if the user hasn't authorized yet.
      options = this._projectOptions(card, projectCache)
    }

    if (options.length <= 0) {
      options = [
        {
          text: "You don't have a project yet. Create a project from the Shape Up dashboard.",
          callback: (t) => {
            t.closePopup();
            auth.conditionalLaunch(t, board, true)
            t.hideCard()
          }
        }
      ]
    }

    // if (currentProject) {
    //   options = options.concat({
    //     alwaysVisible: true,
    //     text: 'Unlink from project',
    //     callback: (t) => {
    //       $tpu.r.scopes.unlink(card.id, currentProject.id, t).then(() => {
    //         t.showCard(card.id)
    //       })
    //     }
    //   })
    // }

    return t.popup({
      title: 'Select destination project',
      items: options
    });
  }

  static _projectOptions(card, projectCache) {
    const projects = Object.values(projectCache)

    return projects.map((project) => {
      return {
        text: project.name,
        callback: (t) => {
          $tpu.r.scopes.link(card.id, project.id, t).then(() => {
            t.showCard(card.id)
          })
        }
      }
    })
  }

  static attachToScope(card, projectCache, cardCache, t, board) {
    let options = []

    if (projectCache) { // Null if the user hasn't authorized yet.
      options = this._cardOptions(card, projectCache, cardCache, t)
    }

    if (options.length <= 0) {
      options = [
        {
          text: "You don't have a scope yet. Create a scope from the dashboard of a Shape Up Project.",
          callback: (t) => {
            t.closePopup();
            auth.conditionalLaunch(t, board, true)
            t.hideCard()
          }
        }
      ]
    }

    return t.popup({
      title: 'Select scope',
      items: options
    });
  }

  static _cardOptions(currentCard, projectCache, cardCache, t) {
    const scopes = []
    Object.values(cardCache).forEach((card) => {
      const project = projectCache[card.shapeUp?.projectId]
      if (project) {
        scopes.push({
          text: `${project.name.truncate(20)} » ${card.name}`,
          fullText: `${project.name} » ${card.name}`, // Custom property for sorting
          callback: (t) => {
            $tpu.r.tasks.link(currentCard.id, card.id, t).then(() => {
              t.showCard(currentCard.id)
            })
          }
        })
      }
    })

    return scopes.sort(this.compareScopeOptions)
  }

  static compareScopeOptions(a, b) {
    return a.fullText.localeCompare(b.fullText)
  }

  // Content may be null
  static badge(content) {
    return Object.assign({
      // This is required even for empty badges because some badges might fail to render temporarily,
      // due to external actions such as server connection issue, actions by other users, etc.
      refresh: 10, // in seconds
    }, content)
  }

  // static badgeColorPopupItems(card) {
  //   const array = Array.from($tpu.epics.colorOptions).map(([key, value]) => {
  //     return {
  //       text: value,
  //       callback: (t) => {
  //         $tpu.epics.update(card, { badgeColor: key }, t).then(() => {
  //           t.showCard(card.id)
  //         })
  //       }
  //     }
  //   })

  //   return array
  // }
}
